import { Link as LinkType } from '~/lib/data-contract';
import {
    ButtonProps,
    TextVariants,
    Text,
    RouterLink,
    Button,
    VisuallyHidden,
} from '~/shared/components';
import { GeneratedThemeShade } from '~/theme';
import {
    StyledTexts,
    StyledTextsWrapper,
    StyledTextBlockForContentCards,
    StyledArrowButtonWrapper,
} from './styled';
import { useTheme } from '@emotion/react';
import RightArrow from '$icons/arrow-right.svg';

export type TextBlockForContentCardsLayout = 'on' | 'below';

export type TextBlockForContentCardsProps = {
    /**
     * The theme of the module/context in which this textBlock is used
     */
    moduleTheme?: Partial<GeneratedThemeShade>;

    /**
     * The headline
     */
    headline?: string;

    /**
     * Which variant the headline is
     */
    headlineVariant?: TextVariants;

    /**
     * The body text
     */
    text?: string;

    /**
     * Link
     */
    callToAction?: LinkType;

    /**
     * Which variant of button to use. If sizes e.g. are needed, extend this
     */
    buttonVariant?: Exclude<ButtonProps['variant'], undefined>;

    /**
     * Which position the button should take, defaults to trait
     */
    textPosition?: TextBlockForContentCardsLayout;

    hideArrowOnMobile?: boolean;

    textLineClamp?: number;
};

/**
 *
 * @description
 * A block of text that can contain a headline, text and a link in the shape of a button.
 * Useful for typical spots, where an image is present, with some accompanying text. E.g. M20.
 * The text can either be below or on top of the image.
 */
export const TextBlockForContentCards = ({
    moduleTheme: initialModuleTheme,
    headline,
    text,
    callToAction,
    buttonVariant,
    headlineVariant = 'display4',
    textPosition,
    hideArrowOnMobile,
    textLineClamp,
    ...rest
}: TextBlockForContentCardsProps) => {
    const { traits } = useTheme();
    // This block can be either on top of contentCards or below. This is decided on a theme basis.
    const position =
        textPosition || (traits.contentCard.textPosition as TextBlockForContentCardsLayout);
    const isTextOnContent = position === 'on';

    const moduleTheme: Partial<GeneratedThemeShade> = {
        ...initialModuleTheme,
        // If the text is on top of content, always choose the inverted shades
        ...(isTextOnContent && {
            headlineShade: 'headlineInverted',
            textShade: 'paragraphInverted',
            buttonShade: 'light',
        }),
    };

    return (
        <StyledTextBlockForContentCards
            position={position}
            backgroundColor={moduleTheme.backgroundColor}
            {...rest}
        >
            <StyledTextsWrapper>
                <StyledTexts>
                    {headline && (
                        <Text
                            children={headline}
                            variant={headlineVariant}
                            shade={moduleTheme?.headlineShade}
                        />
                    )}
                    {text && (
                        <Text shade={moduleTheme?.textShade} variant="caption" lineClamp={textLineClamp}>
                            {text}
                        </Text>
                    )}
                </StyledTexts>
                <StyledArrowButtonWrapper hideOnMobile={hideArrowOnMobile}>
                    {callToAction?.url && !isTextOnContent && (
                        <RouterLink href={callToAction.url}>
                            <Button
                                variant="tertiary"
                                shade={moduleTheme.buttonShade}
                                showHoverIndicator={false}
                            >
                                <VisuallyHidden>
                                    {callToAction?.text || callToAction?.title}
                                </VisuallyHidden>
                                <RightArrow />
                            </Button>
                        </RouterLink>
                    )}
                </StyledArrowButtonWrapper>
            </StyledTextsWrapper>
            {callToAction?.url && isTextOnContent && (
                <RouterLink href={callToAction.url}>
                    <Button
                        title={callToAction.title}
                        variant={buttonVariant}
                        shade={moduleTheme?.buttonShade}
                    >
                        {callToAction.text}
                    </Button>
                </RouterLink>
            )}
        </StyledTextBlockForContentCards>
    );
};
