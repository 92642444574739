import styled from '@emotion/styled';
import { ifNotProp, ifProp } from 'styled-tools';
import { Link } from '~/shared/components/Link';
import { aspectRatio } from '~/shared/utils/styled';
import { breakpoints } from '~/theme';
import { TextBlockForContentCards } from '../TextBlockForContentCards';

export type ImageAspectRatio = { mobile: [number, number]; desktop: [number, number] };

export const StyledCategory = styled(Link)({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
});

export const StyledMediaWrapper = styled.div<
    ImageAspectRatio & { stretch?: boolean; mediaOnly?: boolean }
>(
    {
        position: 'relative',
    },
    ifProp('mediaOnly', ({ mobile }) => ({
        height: '100%',
        flex: 1,
        ...aspectRatio(mobile[0], mobile[1]),

        [breakpoints.md]: {
            aspectRatio: 'unset',
        },
    })),
    ifProp('stretch', {
        flex: 1,
        height: '100%',
    }),
    ifNotProp('stretch', ({ mobile, desktop }) => ({
        ...aspectRatio(mobile[0], mobile[1]),

        [breakpoints.md]: {
            ...aspectRatio(desktop[0], desktop[1]),
        },
    }))
);

export const StyledTextBlock = styled(TextBlockForContentCards)<{ stretchTextContent?: boolean }>(
    {},
    ifProp('stretchTextContent', {
        flex: 1,
        justifyContent: 'flex-start',
        height: '100%',
    })
);

export const StyledImageContainer = styled.div({
    position: 'relative',
    width: '100%',
    height: '100%',
});
