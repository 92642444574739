import React, { FC, ButtonHTMLAttributes } from 'react';
import User from '$icons/solidAsh/user.svg';
import { RouterLink, Tooltip } from '$shared/components';
import { useClub } from '~/features/club';
import { StyledMetaButton } from '../styled';
import { useFrame, useTranslation } from '~/shared/utils';


export const AvatarButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
    const { isAuthenticated, toggleSignInActive } = useClub();
    const { data: frame } = useFrame();
    const { translate } = useTranslation();
    const clubUrl = frame?.staticLinks?.clubPage?.url || '';
    const labelContent = isAuthenticated
        ? translate('accessibility.goToAccount')
        : translate('accessibility.signInSignUp');

    if (!clubUrl) return null;

    return (
        <Tooltip
            content={labelContent}
            describedBy="avatar-tooltip"
            style={{ whiteSpace: 'nowrap' }}
        >
            {isAuthenticated ? (
                <RouterLink href={clubUrl}>
                    <StyledMetaButton as="a" {...props} aria-label={labelContent}>
                        <User />
                    </StyledMetaButton>
                </RouterLink>
            ) : (
                <StyledMetaButton
                    {...props}
                    onClick={(e) => {
                        toggleSignInActive(true);
                        props?.onClick?.(e);
                    }}
                    aria-label={labelContent}
                >
                    <User />
                </StyledMetaButton>
            )}
        </Tooltip>
    );
};
